@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@/core/styles.scss';

.wrapper {
    flex-grow: 1;
}

.formGroup {
    @include separate-top();
    margin-bottom: 3rem;
    padding-top: 2rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
        padding-top: 4rem;
    }
    &:first-child {
        @include media-breakpoint-up(lg) {
            border-top: none;
            padding-top: 0;
        }
    }
}

.item {
    margin-bottom: 1.5rem;
}

.submit {
    @include separate-top();
    padding-top: 3rem;
}
