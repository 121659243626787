@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@/core/styles.scss';

.menu {
    width: 100%;
    @include media-breakpoint-up(lg) {
        max-width: 35rem;
    }
}

.item {
    padding: 2.5rem 0;
    cursor: pointer;

    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }

    @include media-breakpoint-down(sm) {
        padding: 2rem 0;
        text-align: left;
        font-size: 1.6rem;
    }

    &:first-child {
        @include separate-top();
    }
}

.active {
    @include themed() {
        color: themed-color('accent');
    }
}

.menuItemsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuIcon {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
