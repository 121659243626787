@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_breakpoints';

.projectCard {
    text-decoration: none;

    @include themed() {
        color: themed-color('text');
    }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spaceBetween {
    justify-content: space-between;
}

.label {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 1.5rem;

    @include themed() {
        background: themed-color('warning');
    }
}

.arrowIcon {
    @include media-breakpoint-only(xs) {
        display: none;
    }
}
