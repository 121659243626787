@import '~@/core/styles.scss';

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
}

.section {
    @include separate-top();
    @include separate-bottom();
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 3rem;
    &__label {
        margin-bottom: 2rem;
    }
}
