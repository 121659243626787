@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/typography';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.wrapper {
    margin-bottom: 8rem;
    padding-top: 6rem;
    height: 100%;
    width: 100%;
    @include text-size-small();
    @include media-breakpoint-up(sm) {
        margin-bottom: 10rem;
        padding-top: 10rem;
    }
}
