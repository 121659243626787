@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_breakpoints.scss';
@import '~@/core/styles.scss';

.footer {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include media-breakpoint-down(sm) {
        @include separate-top();
    }
}

.gridItem.gridItem.gridItem {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }
}
.copyright {
    white-space: nowrap;
    @include media-breakpoint-down(lg) {
        margin-top: 2rem;
    }
}
