.input {
    margin-bottom: 1.5rem;
}

.agreement {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    padding-top: 3rem;
}

.checkbox {
    margin-right: 0.5rem;
}
