@mixin flex {
    display: flex;
    flex-direction: column;
}

.wrapper {
    position: relative;
    @include flex();
    height: 100%;
    min-height: 100vh;
}

.container {
    @include flex();
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.layout {
    @include flex();
    flex-grow: 1;
}
