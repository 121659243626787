@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints.scss';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
}

.notificationWrapper {
    position: absolute;
    width: 100%;
}
