@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';

.link {
    @include themed() {
        color: themed-color('accent');
    }
    text-decoration: none;
}

@mixin separate-top() {
    @include themed() {
        border-top: 0.2rem solid themed-color('border');
    }
}

@mixin separate-bottom() {
    @include themed() {
        border-bottom: 0.2rem solid themed-color('border');
    }
}
