@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_breakpoints.scss';

.layout {
    margin-bottom: 4rem;
}

.smallMargin {
    margin-bottom: 1rem;
}

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iconWrapper {
    width: 6rem;
    height: 6rem;
    margin-bottom: 4.5rem;
    @include media-breakpoint-up(sm) {
        margin-bottom: 5.6rem;
    }
}

.headingCenter {
    text-align: center;
}
