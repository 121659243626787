@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

.wrapper {
    display: flex;
    width: 100%;
    min-width: 7rem;
    height: 3rem;
    @include media-breakpoint-up(sm) {
        height: 4rem;
    }
}

.vk {
    background: #4a76a8;
    color: #4a76a8;
}
.facebook {
    background: #325595;
    color: #325595;
}
.twitter {
    background: #3cb6e6;
    color: #3cb6e6;
}
.discord {
    background: #7687d7;
    color: #7687d7;
}

.google {
    background: #db4437;
    color: #db4437;
}

.button {
    @include button();
    width: 100%;
    height: 100%;
    color: inherit;
}

.delete {
    @include button();
    border-left: 1px solid #fff;
    width: 3rem;
    @include media-breakpoint-up(sm) {
        width: 4rem;
    }
}

.iconWrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disconnected {
    color: #e3e3e3;
    background: #e3e3e3;
    & .icon {
        background: #999999;
    }
}
