@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.layout {
    height: calc(100vh - 8rem);
    box-sizing: border-box;

    @include media-breakpoint-down(sm) {
        height: auto;
    }
}

.col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        justify-content: start;
    }
}
