@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

.feedbackModalHeader {
    position: relative;
    width: 100%;
    height: 5rem;
    box-sizing: border-box;

    @include themed() {
        background-color: themed-color('background');
        border-bottom: 2px solid themed-color('border');
    }

    &__closeButton {
        @include button();
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
    }
}
