.MessageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40rem;

    &__button {
        width: 100%;

        > button {
            width: 100%;
        }
    }
}
