@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';
@import '~@/core/styles.scss';

.wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 5rem;
    @include themed() {
        background-color: themed-color('background');
    }
    @include separate-bottom();
}

.button {
    @include button();
}

.arrow {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%) rotate(180deg);
}

.cancel {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
}
