@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/typography';

.pageHome {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.leftBackShape {
    position: absolute;
    z-index: -1;
    left: -6.5%;
    width: 40%;
    transform: translateY(-25%);

    @include media-breakpoint-only(sm) {
        transform: translateY(-10%);
        width: 36%;
        left: -11.5%;
    }

    @include media-breakpoint-only(xs) {
        transform: translate(-25%, -35%);
        width: 17rem;
        left: 0;
    }
}

.leftFrontShape {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 30%;

    @include media-breakpoint-only(sm) {
        left: -5%;
        top: 2rem;
    }

    @include media-breakpoint-only(xs) {
        transform: translate(-15%, -15%);
        width: 14rem;
        left: 0;
    }
}

.rightBackShape {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 33.8vh;
    width: 40%;
    transform: translateX(55%);

    @include media-breakpoint-only(sm) {
        width: 35%;
        top: 12rem;
        transform: translateX(45%);
    }

    @include media-breakpoint-only(xs) {
        width: 17rem;
        top: 19rem;
        transform: translateX(35%);
    }
}

.rightFrontShape {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 44vh;
    width: 34%;
    transform: translateX(10%);

    @include media-breakpoint-only(sm) {
        width: 35%;
        top: 16rem;
        transform: translateX(0%);
    }

    @include media-breakpoint-only(xs) {
        width: 19rem;
        top: 21rem;
        transform: translateX(0%);
    }
}

.welcomeScreenWrap {
    position: relative;
    z-index: 0;
}

.mainHeading {
    @include promo-heading-h2();

    @include media-breakpoint-down(sm) {
        @include promo-heading-h1();
    }

    span {
        &:nth-child(1) {
            letter-spacing: 0;
        }
        &:nth-child(2) {
            letter-spacing: -0.025em;
        }
    }
}

.accent {
    @include themed() {
        color: themed-color('accent');
    }

    span {
        &:nth-child(1) {
            letter-spacing: -0.03em;
        }
        &:nth-child(2) {
            letter-spacing: 0;
        }
        &:nth-child(3) {
            letter-spacing: 0;
        }
        &:nth-child(4) {
            letter-spacing: -0.028em;
        }
        &:nth-child(5) {
            letter-spacing: -0.03em;
        }
        &:nth-child(6) {
            letter-spacing: -0.025em;
        }
    }
}

.aboutImage {
    width: 10rem;
    margin-right: 2rem;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spaceBetween {
    justify-content: space-between;
}

.about {
    width: 100%;
    padding: 10rem 0;
    margin-top: 17rem;

    @include themed() {
        background: themed-color('input');
    }

    @include media-breakpoint-only(lg) {
        margin-top: 7rem;
    }

    @include media-breakpoint-only(sm) {
        margin-top: 4rem;
        padding: 8rem 0;
    }

    @include media-breakpoint-only(xs) {
        margin-top: 5rem;
        padding: 4rem 0;
    }
}

.aboutText {
    white-space: pre;
}

.partners {
    margin-top: 25rem;

    @include media-breakpoint-only(lg) {
        margin-top: 15rem;
    }

    @include media-breakpoint-only(sm) {
        margin-top: 12rem;
    }

    @include media-breakpoint-only(xs) {
        margin-top: 8rem;
    }
}

.partnerLogo {
    width: 29rem;
    height: 6rem;

    @include media-breakpoint-only(xs) {
        width: 14.5rem;
        height: 3rem;
    }
}

.contactUs {
    width: 100%;
    padding: 10rem 0;
    margin-top: 25rem;

    @include themed() {
        background: themed-color('accent');
    }

    @include media-breakpoint-only(lg) {
        margin-top: 20rem;
    }

    @include media-breakpoint-only(sm) {
        margin-top: 14rem;
        padding: 8rem 0;
    }

    @include media-breakpoint-only(xs) {
        margin-top: 8rem;
        padding: 4rem 0;
    }
}

.btn {
    &:hover {
        @include themed() {
            border-color: themed-color('text') !important;
            background: themed-color('text') !important;
            color: themed-color('text-inverted') !important;
        }
    }
}

.feedbackForm {
    width: 60rem;
    max-width: 60rem;

    @include media-breakpoint-only(sm) {
        width: auto;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    @include media-breakpoint-only(xs) {
        width: auto;
        max-width: unset;
    }
}
