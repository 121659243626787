@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_breakpoints.scss';

.form {
    margin-bottom: 3rem;
}

.email {
    margin-bottom: 1.5rem;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    padding-top: 3rem;
}
