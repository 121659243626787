@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';

.heading {
    margin-bottom: 6rem;
}

.paddingWrapper {
    width: 100%;
    height: 100%;
    padding-right: 6rem;
}

.menu {
    position: sticky;
    top: 9rem;
    left: 0;
    z-index: 1;
    @include themed() {
        background-color: themed-color('background');
    }
}
