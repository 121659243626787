.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.text {
    margin-bottom: 0.5rem;
}

.item {
    margin-right: 2rem;
}

.actions {
    display: flex;
    flex-wrap: wrap;
}
