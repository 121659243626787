.socialAuth {
    display: flex;
    max-width: 40rem;
}

.buttonWrapper {
    flex-grow: 1;
    &:not(:last-child) {
        margin-right: 1rem;
    }
}
