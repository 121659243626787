@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/global';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    @include themed() {
        background-color: themed-color('background');
    }
}
