@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
    }
}
